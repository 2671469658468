import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  children,
  htmlType,
  disabled,
}) => {

  return (
    <button disabled={disabled} className="font-medium tracking-wide border-2 border-blue-900 text-blue-900 hover:bg-blue-900 hover:text-white px-8 py-4" type={htmlType}>{children}</button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {};

export default Button;
