import React from 'react';
import PropTypes from 'prop-types';

const getClassNameByType = (type) => {
  switch (type) {
    case 'danger':
      return 'bg-red-100 border-red-300 text-red-900';
    case 'success':
      return 'bg-green-100 border-green-300 text-green-900';
    case 'info':
      return 'bg-blue-100 border-blue-300 text-blue-900';
    case 'warning':
      return 'bg-yellow-100 border-yellow-300 text-yellow-900';
    default:
      return '';
  }
};

const Alert = ({
  children,
  type,
}) => {
  return (
    <div className={`${getClassNameByType(type)} border py-4 px-8`}>
      {children}
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

Alert.defaultProps = {
  type: 'info',
};

export default Alert;
