import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  id,
  type,
  name,
  required,
}) => {
  return (
    <input id={id} type={type} name={name} className="form-control" required={required}   />
  );
};

export const InputControlled = ({
  id,
  type,
  name,
  required,
  value,
  onChange,
}) => {
  return (
    <input id={id} type={type} name={name} className="form-control" required={required} value={value} onChange={onChange}   />
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  required: false,
};

InputControlled.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputControlled.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  required: false,
  value: '',
  onChange: () => {},
};

export default Input;
