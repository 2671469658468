import React from 'react';
import PropTypes from 'prop-types';

const FormField = ({
  children,
  htmlFor,
  label,
}) => {

  return (
    <label htmlFor={htmlFor} className="stack-2">
      <span className="text-xs uppercase ml-3">{label}:</span>
      {children}
    </label>
  );
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
};

FormField.defaultProps = {
  htmlFor: null,
  label: null,
};

export default FormField;
