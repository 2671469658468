import * as React from "react";
import Alert from "../components/Alert";
import FormField from "../components/FormField";
import Layout from "../components/Layout";
import Button from "../primitives/Button";
import Input, { InputControlled } from "../primitives/Input";

const baseUrl = "/subscriber";

const getUrlParameter = (name, location) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { alert: "", domain: "", disableSubmit: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ domain: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const postUrl = `${baseUrl}`;
    const regToken = getUrlParameter(
      "x-amzn-marketplace-token",
      this.props.location.search
    );

    if (!regToken) {
      this.setState({
        alert:
          "Registration Token Missing. Please go to AWS Marketplace and follow the instructions to set up your account!",
        alertType: "danger",
      });
    } else {
      const data = {
        companyName: event.target.companyName.value,
        contactPerson: event.target.contactPerson.value,
        contactPhone: event.target.contactPhone.value,
        contactEmail: event.target.contactEmail.value,
        workAddress1: event.target.workAddress1.value,
        workAddress2: event.target.workAddress2.value,
        country: event.target.country.value,
        city: event.target.city.value,
        domain: event.target.domain.value,
      };
      data.regToken = regToken;

      const xhr = new XMLHttpRequest();

      xhr.open("POST", postUrl, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(data));

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            this.setState({ alert: xhr.responseText, alertType: "success", disableSubmit: true });
          } else {
            this.setState({ alert: xhr.responseText, alertType: "warning" });
          }
        }
      };
    }
  }


  render() {
    return (
      <Layout>
        <div className="stack-6 w-full">
          {this.state.alert && (
            <Alert type={this.state.alertType}>{this.state.alert}</Alert>
          )}
          <form
            className="stack-6 max-w-md mx-auto w-full"
            onSubmit={this.handleSubmit}
          >
            <h1 className="text-xl font-bold text-center">
              Please enter your contact details
            </h1>

            <FormField label="Full Name*" htmlFor="contactPerson">
              <Input id="contactPerson" name="contactPerson" required />
            </FormField>

            <FormField label="Email address*" htmlFor="contactEmail">
              <Input
                id="contactEmail"
                type="email"
                name="contactEmail"
                required
              />
            </FormField>

            <FormField label="Contact Number*" htmlFor="contactPhone">
              <Input
                id="contactPhone"
                type="tel"
                name="contactPhone"
                required
              />
            </FormField>
            <FormField label="Organization Name*" htmlFor="companyName">
              <Input id="companyName" name="companyName" required />
            </FormField>

            <FormField label="Office Address 1" htmlFor="workAddress1">
              <Input id="workAddress1" name="workAddress1" />
            </FormField>
            <FormField label="Office Address 2" htmlFor="workAddress2">
              <Input id="workAddress2" name="workAddress2" />
            </FormField>

            <FormField label="City" htmlFor="city">
              <Input id="city" name="city" />
            </FormField>
            <FormField label="Country*" htmlFor="country">
              <Input id="country" name="country" required />
            </FormField>

            <FormField label="Preferred site url*" htmlFor="domain">
              <InputControlled
                id="domain"
                name="domain"
                value={this.state.domain}
                onChange={this.handleChange}
                required
              />
              <div>Example: {this.state.domain}.zedoc.io</div>
            </FormField>

            <Button htmlType="submit" disabled={this.state.disableSubmit}>Register</Button>
          </form>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
